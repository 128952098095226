.headerText {
  font-family: Helvetica !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 23px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #3b3f4d !important;
  margin-top: 15px !important;
}

.icon {
  filter: invert(37%) sepia(69%) saturate(3991%) hue-rotate(228deg) brightness(97%) contrast(93%);
}
