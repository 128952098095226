.cardHeader {
  padding: 14px 24px;
}

.cardHeaderTitle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #ffffff;
}

.cardEmptyText {
  font-family: 'Helvetica';
  font-style: normal;
  text-align: center;
  padding-top: 40px;
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 21px;
  color: rgba(59, 63, 77, 0.5);
}
