.document {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.actions {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  position: sticky;
  top: 0;
}
