.accordionTitle {
  background-color: #5d5fef !important;
  border-radius: 5px !important;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 18px;
  color: #ffffff !important;
}

.accordionTitleText {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
}

.accordionMore {
  margin-left: auto;
}
